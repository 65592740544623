import React from 'react';
import stylesBI from './Boxinfo.module.css';
import distractionBox from './images/distraction-box.png'
import comfortBox from './images/comfort-box.png'
import atHomeBox from './images/at-home-box.png'
import products from '../Globals/productData.js'
import {
  Link
} from "react-router-dom";
Object.freeze(products)


class Boxinfo extends React.Component{
constructor(props){
  super(props)
  this.state = {
    default: null,
  }
}

buyProduct = (target) => {
  var productNumber = target.target.innerText[target.target.innerText.length - 1]
  var webUrl = null

  fetch("https://nightingaleguide-backend.herokuapp.com/shopify/" + productNumber)
  .then(res => res.json())
  .then(res => {
    webUrl = res
    window.location.assign(webUrl)
    }
  )
}

render(){
  return(
    <section className={stylesBI["box-info-section"]}>
      <div className={stylesBI["box-info-section-box"]}>
        <div className={stylesBI["left-box"]}>
          <img alt="distraction-box" src={distractionBox} className={stylesBI["box-image"]} />
        </div>
        <div className={stylesBI["right-box"]}>
          <div className={stylesBI["right-box-h2-holder"]}>
            <h2 className={stylesBI["right-box-box-h2"]}>The Distraction Box</h2>
          </div>
          <div className={stylesBI["right-box-p-holder"]}>
            <p>We interviewed patients to hear what they actually want and need. One of the things we heard most was that with all the time spent in infusion centers, hospitals, or home in bed - fun games, books and activities make some of the best gifts!</p>
          </div>
          <div className={stylesBI["right-box-a-holder"]}>
            <Link to={{pathname: `/shop/distraction-box`, state: {data: products[0]}}} className={stylesBI["right-box-yellow-action-button"]}>Shop Distraction Box</Link>
          </div>
        </div>
      </div>
      <div className={stylesBI["box-info-section-box"]}>
        <div className={stylesBI["right-box-box"]}>
          <div className={stylesBI["right-box-h2-holder"]}>
            <h2 className={stylesBI["right-box-box-h2"]}>The Comfort Box</h2>
          </div>
          <div className={stylesBI["right-box-p-holder"]}>
            <p>Can't physically be there to hold their hand and provide a moment of happiness? The Comfort Box is designed to provide comfort for the body and spirit. Each item in this box helps your loved one stay organized and find a moment of calm or control in an otherwise stressful time.</p>
          </div>
          <div className={stylesBI["right-box-a-holder"]}>
            <Link to={{pathname: `/shop/comfort-box`, state: {data: products[1]}}} className={stylesBI["right-box-yellow-action-button"]}>Shop Comfort Box</Link>
          </div>
        </div>
        <div className={stylesBI["left-box-box"]}>
          <img alt="comfort-box" src={comfortBox} className={stylesBI["box-image-2"]} />
        </div>
      </div>
      <div className={stylesBI["box-info-section-box"]}>
        <div className={stylesBI["left-box"]}>
          <img alt="at-home-box" src={atHomeBox} className={stylesBI["box-image"]} />
        </div>
        <div className={stylesBI["right-box"]}>
          <div className={stylesBI["right-box-h2-holder"]}>
            <h2 className={stylesBI["right-box-box-h2"]}>Stuck At Home Box</h2>
          </div>
          <div className={stylesBI["right-box-p-holder"]}>
            <p>This box is perfect for anyone who is returning home from the hospital or will be on bed rest. This the ideal gift for anyone that needs help combatting boredom during quarantine.</p>
          </div>
          <div className={stylesBI["right-box-a-holder"]}>
            <Link to={{pathname: `/shop/stuck-at-home-box`, state: {data: products[2]}}} className={stylesBI["right-box-yellow-action-button"]}>Shop At Home Box</Link>
          </div>
        </div>
      </div>
    </section>
  )
}
}

export default Boxinfo;

import Homepage from './home/index.js';
import Shop from './shop/index.js';
import Product from './product/index.js';
import Blog from './blog/index.js';
import BlogPost from './blog/blogPost.js';
import './App.css';
import {
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route name="blogpost" exact path='/blog/:post/' component={BlogPost}/>
        <Route name="blog" path="/blog" component={Blog}/>
        <Route name="product" exact path='/shop/:item/' component={Product}/>
        <Route path="/shop" component={Shop} />
        <Route path="/" component={Homepage} />
      </Switch>
    </Router>
  );
}

export default App;

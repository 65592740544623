import React from 'react';
import './Testimonials.css'
import person1 from './images/Alanna.png'
import person2 from './images/Cambor.png'
import person3 from './images/Lindsey.png'
import person4 from './images/Marcy.png'

class Testimonials extends React.Component{
constructor(props){
  super(props)
  this.state = {
    default: null,
  }
}


render(){
  return(
    <section className="Testimonials">
      <div className="Testimonials-container">
      <div className="Testimonials-container-h3"></div>
        <div className="Testimonials-container-h2">
          <h2>They Say It Better Than We Do</h2>
        </div>
        <div className="Testimonials-container-row">
          <div className="Testimonials-container-column-left">
            <div className="Testimonials-container-row-box-1">
              <div className="Testimonials-container-row-box-header-text">
                  My box was literally so perfect for after ACL surgery since I can't really go anywhere. I am very excited to do the sudoku in particular!
              </div>
              <div className="Testimonials-container-author-area">
                <div className="Testimonials-container-row-box-image">
                  <img alt="alanna" src={person1} />
                </div>
                <div className="Testimonials-container-author-info-area">
                  <div className="Testimonials-container-author-info-name">
                    Alanna
                  </div>
                  <div className="Testimonials-container-author-info-location">
                    New York, NY
                  </div>
                </div>
              </div>
            </div>
            <div className="Testimonials-container-row-box-2">
              <div className="Testimonials-container-row-box-header-text">
                  I sent a box to my friend when she was home with COVID and she loved the thoughtful gifts - the handwritten note was the cherry on top!
              </div>
              <div className="Testimonials-container-author-area">
                <div className="Testimonials-container-row-box-image">
                  <img alt="camber" src={person2} />
                </div>
                <div className="Testimonials-container-author-info-area">
                  <div className="Testimonials-container-author-info-name">
                    Camber
                  </div>
                  <div className="Testimonials-container-author-info-location">
                    Stowe, VT
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Testimonials-container-column-right">
            <div className="Testimonials-container-row-box-3">
              <div className="Testimonials-container-row-box-header-text">
                  I love my box so much!!! It seriously brightened my day so much when I was stuck at home alone sick. I loved everything in it!
              </div>
              <div className="Testimonials-container-author-area">
                <div className="Testimonials-container-row-box-image">
                  <img alt="Lindsey" src={person3} />
                </div>
                <div className="Testimonials-container-author-info-area">
                  <div className="Testimonials-container-author-info-name">
                    Lindsey
                  </div>
                  <div className="Testimonials-container-author-info-location">
                    Denver, CO
                  </div>
                </div>
              </div>
            </div>
            <div className="Testimonials-container-row-box-4">
              <div className="Testimonials-container-row-box-header-text">
                Thae gift was the sweetest card and care package - it was so thoughtful! Nightingale has me feeling so much love and support.
              </div>
              <div className="Testimonials-container-author-area">
                <div className="Testimonials-container-row-box-image">
                  <img alt="Marcy" src={person4} />
                </div>
                <div className="Testimonials-container-author-info-area">
                  <div className="Testimonials-container-author-info-name">
                    Marcy
                  </div>
                  <div className="Testimonials-container-author-info-location">
                    Washington, DC
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
}

export default Testimonials;

import React from 'react';
import './Main.css'
import headerImage from './images/marilyn.svg'
import {
  Link
} from "react-router-dom";


class Main extends React.Component{
constructor(props){
  super(props)
  this.state = {
    default: null,
  }
}

render(){
  return(
    <section>
      <div className="background-image-div">
        <div className="max-width">
          <div className="full-width">
            <div className="text-holder">
              <h1 className="main-h1">Show You Care With a Thoughtful Gift</h1>
              <div className="secondary-row-holder">
                <div className="secondary-text-holder">
                  <h2 className="main-h2">We guide you through one of life's most challenging moments. Customize a care package and we'll do the rest.</h2>
                  <div className="button-holder">
                    <Link className="yellow-action-button" to={{pathname: `/shop`}}>Gift a Box</Link>
                    <Link className="white-action-button" to={{pathname: '/blog/about-us', state:{ postID : "102" }}}>Learn More</Link>
                  </div>
                </div>
                <div className="header-image-holder">
                  <img alt="marilyn-cancer-box" src={headerImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
}

export default Main;

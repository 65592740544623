import React from 'react';
import './Boxslider.css';
import Boxone from './Boxone.js'
import products from '../Globals/productData.js'
import {
  Link
} from "react-router-dom";

class Boxslider extends React.Component{
constructor(props){
  super(props)
  this.state = {
    selected: "1",
    descriptionText: "This box features boredom busting games and activities to keep the mind active and distracted during recovery.[BULLET]3-in-1 travel game set OR DIY embroidery kit[BULLET]Dammit Doll: Choose one of three free patterns[BULLET]A large-print sudoku and crossword book[BULLET]Handwritten card with your message",
    buttonText: "Shop Distraction Box",
    productTitle: "Distraction Box",
    productPrice: "$55",
    productLink: "distraction-box"
  }
}

selectedProduct = (target) => {
  var descriptionTextMap = {
    "1": "This box features boredom busting games and activities to keep the mind active and distracted during recovery.[BULLET]3-in-1 travel game set OR DIY embroidery kit[BULLET]Dammit Doll: Choose one of three fun patterns[BULLET]A large-print sudoku and crossword book[BULLET]Handwritten card with your message",
    "2": "This box features products selected to encourage organization, peacefulness, and relaxation during recovery.[BULLET]Travel Pouch: Choose one of three fun patterns[BULLET]Journal: Choose a cover pattern[BULLET]JOY Candle: Infused, homemade soy candle[BULLET]Flower Food: 2 packets to keep flowers happy[BULLET]Sleep mask[BULLET]Handwritten card with your message",
    "3": "This box is perfect for anyone who will be on bed rest or needs help combatting boredom during quarantine."
  }
  var buttonTextMap = {
    "1": "Shop Distraction Box",
    "2": "Shop Comfort Box",
    "3": "Stuck At Home Box"
  }
  var productTitleMap = {
    "1":"Distraction Box",
    "2":"Comfort Box",
    "3":"Stuck At Home Box"
  }

  var productPriceMap = {
    "1":"$55",
    "2":"$65",
    "3":"$70"
  }
  var productLinkMap = {
    "1":"distraction-box",
    "2":"comfort-box",
    "3":"stuck-at-home-box"
  }

  if (parseInt(target.target.id) > 0 || target.target.className === "BrainhubCarousel"){
    //CATCH
  }

  else if (target.target.parentElement.id === "right-arrow" || target.target.className.baseVal.includes("right")){
    //RIGHT ARROWS
    var currentIndex = parseInt(this.state.selected) + 1
    if (currentIndex <= 3){
      this.setState({
        selected: currentIndex.toString(),
        descriptionText: descriptionTextMap[currentIndex.toString()],
        buttonText: buttonTextMap[currentIndex.toString()],
        productTitle: productTitleMap[currentIndex.toString()],
        productPrice: productPriceMap[currentIndex.toString()],
        productLink: productLinkMap[currentIndex.toString()]
      })
    }
    else if (currentIndex === 4){
      this.setState({
        selected: "1",
        descriptionText: descriptionTextMap["1"],
        buttonText: buttonTextMap["1"],
        productTitle: productTitleMap["1"],
        productPrice: productPriceMap["1"],
        productLink: productLinkMap["1"]
      })
    }
  }

  else if (target.target.parentElement.id === "left-arrow" || target.target.className.baseVal.includes("left")){
    //LEFT ARROWS
    var currentIndex = parseInt(this.state.selected) - 1
    if (currentIndex >= 1){
      this.setState({
        selected: currentIndex.toString(),
        descriptionText: descriptionTextMap[currentIndex.toString()],
        buttonText: buttonTextMap[currentIndex.toString()],
        productTitle: productTitleMap[currentIndex.toString()],
        productPrice: productPriceMap[currentIndex.toString()],
        productLink: productLinkMap[currentIndex.toString()]
      })
    }
    else if (currentIndex === 0){
      this.setState({
        selected: "3",
        descriptionText: descriptionTextMap["3"],
        buttonText: buttonTextMap["3"],
        productTitle: productTitleMap["3"],
        productPrice: productPriceMap["3"],
        productLink: productLinkMap["3"]
      })
    }
  }
}

render(){
  var descriptionTextFormatted = this.state.descriptionText.split("[BULLET]")
  var descriptionTextStarter = descriptionTextFormatted.shift()
  return(
    <section className="boxSlider">
      <div>
        <h2 className="h2-boxSlider-holder">Our Boxes</h2>
        <Boxone selectedProduct={this.selectedProduct} selected={this.state.selected} border={this.state.border}/>
        <div className="title-price-holder">
          <div>{this.state.productTitle}</div>
          <div className="mobile-shop-price">{this.state.productPrice}</div>
        </div>
        <div className="descriptiontext_box">
          <div className="descriptiontext">
            <div className="description-text-starter-mobile">
              {descriptionTextStarter}
            </div>
          </div>
          <div className="mobile-shop-a-holder">
            {this.state.buttonText != null ?
              (this.state.buttonText !== "Coming soon!" ?
                <Link to={{pathname: `/shop/${this.state.productLink}`, state: {data: products[parseInt(this.state.selected) - 1]}}} className="right-box-yellow-action-button">{this.state.buttonText}</Link>
              :
                <Link to={{pathname: `/shop/`, state: {data: products[parseInt(this.state.selected) - 1]}}} className="right-box-yellow-action-button">{this.state.buttonText}</Link>
              )
            :
              null
            }
          </div>
        </div>
        <div className="shop_box_holder" style={{display: "none"}}>
          <div>
            <div>
              {this.state.selected === "1" ?
                <div className="selected" id="1">
                </div>
              :
                <div className="not_selected" id="1">
                </div>
              }
            </div>
          </div>
          <div>
            <div>
              {this.state.selected === "2" ?
                <div id="2" className="selected">
                </div>
              :
                <div id="2" className="not_selected">
                </div>
              }
            </div>
          </div>
          <div>
            <div>
              {this.state.selected === "3" ?
                <div id="3" className="selected">
                </div>
              :
                <div id="3" className="not_selected">
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
}

export default Boxslider;

import React from "react";
import logo from "./images/logo.png";
import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import "./Footer.css";
import {
  Link
} from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      default: null
    };
  }

  render() {
    return (
      <footer className="footer">
        <div className="footer__sections container">
          <div className="footer__section logo">
            <a href="/">
              <div className="footerLogoIcon">
                <img alt="nightingale-footer-logo" src={logo} />
              </div>
            </a>
          </div>
          <div className="footer__section">
            <span className="footer__title">Service</span>
            <Link className="footer__link" to={{pathname: `/shop`}}>
              Our Boxes
            </Link>
            <a href="#" className="footer__link">
              How It Works
            </a>
            <a href="#" className="footer__link">
              Gift Cards
            </a>
            <a href="#" className="footer__link">
              Login
            </a>
            <a href="#" className="footer__link">
              Sign Up
            </a>
            <a href="#" className="footer__link">
              Seniors
            </a>
            <a href="#" className="footer__link">
              Terms of Service
            </a>
            <a href="#" className="footer__link">
              Privacy Policy
            </a>
            <a href="#" className="footer__link">
              Don't Sell Info
            </a>
          </div>
          <div className="footer__section">
            <span className="footer__title">Resources</span>
            <a href="#" className="footer__link">
              Nightingale Guide
            </a>
            <a href="#" className="footer__link">
              Categories of Care
            </a>
            <a href="#" className="footer__link">
              FAQs & Support
            </a>
            <a href="#" className="footer__link">
              Affiliate Program
            </a>
          </div>
          <div className="footer__section">
            <span className="footer__title">Company</span>
            <a href="#" className="footer__link">
              About Us
            </a>
            <a href="#" className="footer__link">
              Careers
            </a>
            <a href="#" className="footer__link">
              Press
            </a>
            <a href="#" className="footer__link">
              Legal
            </a>
            <a href="#" className="footer__link">
              Stores
            </a>
          </div>
          <div className="footer__section">
            <span className="footer__title">Customer Support</span>
            <div className="footerImageHolder">
              <a href="https://www.instagram.com/nightingaleguide/" target="_blank">
                <img alt="instagram-icon" className="socialFooter-1" src={instagram} />
              </a>
              <a href="https://www.facebook.com/nightingaleguide" target="_blank">
                <img alt="facebook-icon" className="socialFooter-2" src={facebook} />
              </a>
            </div>
            <a href="#" className="footer__link-support">
              Contact@nightingaleguide.com
            </a>
            <a href="#" className="footer__link-support">
              (212) 999 - 1234
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation.js";
import Footer from "../components/Footer/Footer.js";
import Ecap from "../components/eCap/eCap.js";
import blogHeader from './images/BlogHeader.svg';
import flower1 from './images/Flower1.svg';
import flower2 from './images/Flower2.svg';
import flower3 from './images/Flower3.svg';
import searchEllipse from './images/SearchEllipse.svg';
import searchLine from './images/SearchLine.svg';
import chevron from './images/chevron.svg';
import x from './images/x.svg';
import postDivider from './images/postDivider.svg';
import "./blog.css";
import none from './images/box1.png';
import SEO from '@americanexpress/react-seo';

function SortByMenu(props) {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="blog-sort-container" ref={ref}>

      <div className="blog-sortby-container" onClick={() => setOpen(!open)}>
        <p className="blog-sortby">Sort By</p>
        <img src={chevron} alt="back-chevron" className={`${'blog-chevron'} ${open && "open"}`}/>
      </div>
      {open &&
        <nav
            className={`${'blog-sortby-menu'} ${open && "active"}`}
            styles={{display: open ? "block" : "none"}}>
            <ul>
                <li onClick={props.menuClick1} className={`${props.filterOption === 1 && "active"}`}>
                    <p>Newest to Oldest</p>
                </li>
                <li onClick={props.menuClick2} className={`${props.filterOption === 2 && "active"}`}>
                    <p>Oldest to Newest</p>
                </li>
            </ul>
        </nav>
      }
      {!open && props.filterOption &&
        <div onClick={props.menuReset} className="blog-sortby-selected">
          <p className="blog-sortby-selected-text">{props.filterOption === 1 && "Newest to Oldest" || props.filterOption === 2 && "Oldest to Newest"}</p>
          <img src={x} alt="x-button" className="blog-sortby-selected-x"/>
        </div>
      }
    </div>
  );
}

function parseDate(date) {
    const parsed = date.split('-')
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return months[parsed[1] - 1] + " " + parsed[0]
}

function filterPosts(state) {
  // filter oldest to newest or newest to oldest

  if(state.filterOption === 1){

    return state.posts.filter(post => post.title.replace('&#8217;', "'").toLowerCase().includes(state.userQuery.toLowerCase())).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

  } else if (state.filterOption === 2) {

    return state.posts.filter(post => post.title.replace('&#8217;', "'").toLowerCase().includes(state.userQuery.toLowerCase())).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  } else { //null state

    return state.posts.filter(post => post.title.replace('&#8217;', "'").toLowerCase().includes(state.userQuery.toLowerCase()))

  }
}

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      userQuery: "",
      filterOption: 1,
      visible: false
    };
  }

  componentDidMount(){
    this.fetchPostData()
    //https://gist.github.com/BretCameron/d47bcbe15c82de521640136199813889
    //used this methodology
  }

  fetchPostData(){
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.toggleVisible)
    const url = new URL("https://nightingaleguide-backend.herokuapp.com/fetchblogposts")

    try {
      fetch(url)
      .then(response => response.json())
      .then(response => {

        // EVERYTHING BELOW THIS PUTS A STANDARDIZED IMAGE "NONE" FOR POSTS THAT DON'T HAVE AN IMAGE
        var imagelessPosts = response.filter(post => post.image === null)
        var finalPosts = response.filter(post => post.image !== null)

        imagelessPosts.forEach(post => {
          var editedPost = post
          editedPost.image = none
          finalPosts.push(editedPost)
        })
        // EVERYTHING ABOVE THIS PUTS A STANDARDIZED IMAGE "NONE" FOR POSTS THAT DON'T HAVE AN IMAGE
        this.setState({
          posts: response
        })

      })

    } catch(error) {
      console.error(error)
    }

  }

  blogSearchCapture = (event) => {
    event.preventDefault()
    this.setState({
      userQuery: event.target.value,
    })

  }

  menuReset = () => {
    this.setState({
      filterOption: null
    })
  }

  menuClick1 = () => {
    this.setState({
      filterOption: 1
    })
  }

  menuClick2 = () => {
    this.setState({
      filterOption: 2
    })
  }

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400){
      this.setState({
        visible: true
      })
    }
    else if (scrolled <= 400){
      this.setState({
        visible: false
      })
    }
  }

  scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
  }

  render() {

    return (
      <div className="blogbg">
        <SEO
          title={"Long Term Care Resources | Nightingale Guide Blog"}
          description="Nightingale Guide aims to make sure that patients, friends, and family members are equipped with the knowledge and resources they need to make any tough health situation more manageable."
          openGraph={{
            title: "Long Term Care Resources | Nightingale Guide Blog",
            description: "Nightingale Guide aims to make sure that patients, friends, and family members are equipped with the knowledge and resources they need to make any tough health situation more manageable."
          }}
          twitterCard={{
            title: "Long Term Care Resources | Nightingale Guide Blog",
            description: "Nightingale Guide aims to make sure that patients, friends, and family members are equipped with the knowledge and resources they need to make any tough health situation more manageable."
          }}
        />
        <Navigation />
        <div className="blog-background-holder">
          <div className="blog-header-image-holder">
            <img src={blogHeader} alt="header-blog-background" className="blog-header-image"/>
          </div>
          <div className="blog">
            <img src={flower1} alt="flower-1" className="flower1"/>
            <img src={flower2} alt="flower-2" className="flower2"/>
            <img src={flower3} alt="flower-3" className="flower3"/>
            <p className="resources">Resources</p>
            <div className="blog-search-container">
              <form className="blog-search">
                <input className="blog-search-input" onChange={this.blogSearchCapture}/>
                <button type="submit" className="blog-search-button">
                  <img src={searchEllipse} alt="search-elipse" className="blog-search-ellipse"/>
                  <img src={searchLine} alt="search-line" className="blog-search-line"/>
                </button>
              </form>
            <SortByMenu filterOption={this.state.filterOption} menuReset={this.menuReset} menuClick1={this.menuClick1} menuClick2={this.menuClick2} />

            </div>
            <div className="blog-post-container">

              {filterPosts(this.state).map((post, index) =>
                <React.Fragment key={"post-" + index}>
                  <Link to={{ pathname: `/blog/${post.slug}`, state:{ postID : post.id } }}>
                    <div className="blog-post">
                      <div className="blog-post-date">{parseDate(post.date)}</div>
                      <img alt={"blog-post" + post.image} className="blog-post-image" src={post.image} />
                      <div className="blog-post-title-holder">
                        <div dangerouslySetInnerHTML={{ __html: post.title }} className="blog-post-title"/>
                      </div>
                      <img alt="post-divider" className="post-divider" src={postDivider} />
                      <div className="blog-post-author">{"By " + post.author}</div>
                    </div>
                  </Link>
                </React.Fragment>
              )}

            </div>
            <button className="blog-back-to-top" onClick={this.scrollToTop} style={{display: this.state.visible ? 'inline' : 'none'}}>Back to top</button>
          </div>
        </div>
        <Ecap />
        <Footer />
      </div>
    );
  }
}

export default Blog;

import React from 'react';
import './DesktopShop.css';
import DesktopShopProduct from '../DesktopShopProduct/DesktopShopProduct.js'
import products from '../Globals/productData.js'

class DesktopShop extends React.Component{
constructor(props){
  super(props)
  this.state = {
    products: [{}]
  }
}

componentDidMount(){
  window.scrollTo(0, 0)
}

render(){
  return(
    <section className="boxSlider">
      <div>
        <div className="boxHolder">
        {products.map((product, index) => {
          return <DesktopShopProduct key={index+Math.random()} productImage={product.productImage} descriptionText={product.descriptionText} buttonText={product.buttonText} productTitle={product.productTitle} productPrice={product.productPrice} productLink={product.productLink} otherProducts={product.otherProducts}/>;
        })}
        </div>
      </div>
    </section>
  )
}
}

export default DesktopShop;

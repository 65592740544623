import React from 'react';
import './DesktopShopProduct.css';
import {
  Link
} from "react-router-dom";

class DesktopShopProduct extends React.Component{

  render(){
    var descriptionTextFormatted = this.props.descriptionText.split("[BULLET]")
    var descriptionTextStarter = descriptionTextFormatted.shift()
    return(
      <div className="desktop-shop-product-holder">
        <img alt={"product-image" + this.props.productImage} src={this.props.productImage} className="product-image"/>
        <div className="title-price-holder">
          <div>{this.props.productTitle}</div>
          <div className="product-price-holder">{this.props.productPrice}</div>
        </div>
        <div className="descriptiontext_box">
          <div className="descriptiontext">
            <div className="description-text-starter">
              {descriptionTextStarter}
            </div>
          </div>
          <div className="product-button-holder">
            {this.props.buttonText !== "Coming soon!" ?
              <Link to={{pathname: `/shop/${this.props.productLink}`, state: {data: this.props}}} className="right-box-yellow-action-button">{this.props.buttonText}</Link>
            :
              <Link to={{pathname: `/shop/`, state: {data: this.props}}} className="right-box-yellow-action-button">{this.props.buttonText}</Link>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default DesktopShopProduct;

import React from 'react';
import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './Boxslider.css';
import '@fortawesome/fontawesome-free/js/all.js';
import homeBox from './images/at-home-box.png';
import distractionBox from './images/distraction-box.png'
import comfortBox from './images/comfort-box.png'

class Boxone extends React.Component{
  constructor(props){
    super(props)
    this.state = {
    }
  }

  render(){
    return(
      <div onClick={this.props.selectedProduct}>
        <Carousel plugins={['infinite', 'clickToChange', {
          resolve: arrowsPlugin,
          options: {
            arrowLeft: <button id="left-arrow" className="fas fa-arrow-circle-left" />,
            arrowLeftDisabled:<button></button>,
            arrowRight: <button id="right-arrow" className="fas fa-arrow-circle-right" />,
            arrowRightDisabled: <button></button>,
            addArrowClickHandler: true
          }
        }]}
          slides={
            [
              (<img src={distractionBox} alt="distraction-box" id="1" className="product-image-slider" />),
              (<img src={comfortBox} alt="comfort-box" id="2" className="product-image-slider"/>),
              (<img src={homeBox} alt="stuck-at-home" id="3" className="product-image-slider"/>)
            ]
          }
          draggable={false}
        />
      </div>
    )
  }
}

export default Boxone;

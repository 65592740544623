import React from 'react';
import './eCap.css';

class Ecap extends React.Component{
constructor(props){
  super(props)
  this.state = {
    signup: false,
    falseSignup: false
  }
}

emailCapture = (target) => {
  target.preventDefault()
  var emailAddress = target.target[0].value
  fetch("https://nightingaleguide-backend.herokuapp.com/signup/" + emailAddress)
  .then(res => res.json())
  .then(res => {
    if (res.status === "subscribed"){
      this.setState({
        signup: true,
        falseSignup: false,
      })
    }
    else {
      this.setState({
        falseSignup: true
      })
    }
  })
}


render(){
  return(
    <section className="ecap-container">
      <div className="eCap-box">
        <div className="eCap-box-h2">
          <h2>Join our mailing list!</h2>
        </div>
        {this.state.signup === false ?
          <div className="eCap-box-holder">
            <form className="eCap-box-button" onSubmit={this.emailCapture}>
              <input className="eCap-input"/>
              <button type="submit" className="eCap-button">signup</button>
            </form>
          </div>
          :
          <div className="eCap-submitted">
            Thank you for signing up!
          </div>
        }
        {this.state.falseSignup === true ?
          <div className="eCap-invalid">
            This email isn't valid for our lists! Please try again.
          </div>
          :
          null
        }
      </div>
    </section>
  )
}
}

export default Ecap;

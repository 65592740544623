import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation.js";
import Footer from "../components/Footer/Footer.js";
import Ecap from "../components/eCap/eCap.js";
import backChevron from './images/backChevron.svg'
import "./blog.css";
import SEO from '@americanexpress/react-seo';
import none from './images/box1.png'

class BlogPost extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        id: null,
        image: null,
        title: null,
        author: null,
        content: null,
        description: null
      };
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // FIRST IF STATEMENT DEALS WITH PEOPLE WHO GO DIRECT TO PAGE - SECOND ELSE IF IS FOR PEOPLE WHO DON'T NEED AN ADDITIONAL FETCH
        // AND LAND DIRECT ON THE BLOG POST
        if (this.props.location.state === undefined){
          var postID = window.location.pathname.split("/blog/")[1]
          fetch("https://nightingaleguide.com/blogadmin/wp-json/wp/v2/posts?slug=" + postID + "&_embed")
          .then(res => res.json())
          .then(res => {
            var post = res[0]
              this.setState({
                  image: post._embedded["wp:featuredmedia"] === undefined ? null : post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url,
                  title: post.title.rendered === undefined ? null : post.title.rendered,
                  author: post._embedded.author[0].name === undefined ? null : post._embedded.author[0].name,
                  content: post.content.rendered === undefined ? null : post.content.rendered,
                  description: post.yoast_head_json.og_description === undefined ? null : post.yoast_head_json.og_description
            })
          })
        }

        else if (this.props.location.state.postID !== undefined){
          this.setState({
            id: this.props.location.state.postID
          }, () => {
            fetch("https://nightingaleguide.com/blogadmin/wp-json/wp/v2/posts/" + this.state.id + "?_embed")
            .then(res => res.json())
            .then(post => {
                this.setState({
                    image: post._embedded["wp:featuredmedia"] === undefined ? none : post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url,
                    title: post.title.rendered === undefined ? null : post.title.rendered,
                    author: post._embedded.author[0].name === undefined ? null : post._embedded.author[0].name,
                    content: post.content.rendered === undefined ? null : post.content.rendered,
                    description: post.yoast_head_json.og_description === undefined ? null : post.yoast_head_json.og_description
              })
            })
          })
        }
    }

    render() {
    return (
      <div className="blogbg">
      <SEO
        title={this.state.title + " | Nightingale Guide Blog"}
        description={this.state.description}
        keywords={["cancer care package", "emotional care box", "long term care box", "covid gifts", "cancer gifts", "hospital care package"]}
        openGraph={{
          title: this.state.title + " | Nightingale Guide Blog",
          description: this.state.description
        }}
        twitterCard={{
          title: this.state.title + " | Nightingale Guide Blog",
          description: this.state.description
        }}
      />
        <Navigation />
        <div className="blog-back-holder">
          <Link to={{pathname: "/blog/"}}>
            <div className="blog-back">
              <img className="blog-back-button" alt="back-chevron" src={backChevron} />
              Back to Resources
            </div>
          </Link>
        </div>
        <div className="blogPost-container">
          <div className="blogPost-image-container">
            <img className="blogPost-image" alt={this.state.image + "featured"} src={this.state.image} />
          </div>
          <div className="blogPost-title" dangerouslySetInnerHTML={{ __html: this.state.title }} />
          <div className="blogPost-author">{"By " + this.state.author}</div>
          <div className="blogPost-content" dangerouslySetInnerHTML={{ __html: this.state.content }} />
          <Link to={{pathname: "/blog/"}}>
            <div className="blog-back">
              <img className="blog-back-button" alt="back-chevron" src={backChevron} />
              Back to Resources
            </div>
          </Link>
        </div>
        <Ecap />
        <Footer />
      </div>
    );
  }
}

export default BlogPost;

import boxImage from './images/box1.png';
import distractionBox from './images/distraction-box.png'
import comfortBox from './images/comfort-box.png'
import stuckAtHomeBox from './images/at-home-box.png'

var descriptionTextMap = {
  "1": "This box features boredom busting games and activities to keep the mind active and distracted during recovery.[BULLET]3-in-1 travel game set OR DIY embroidery kit[BULLET]Dammit Doll: Choose one of three fun patterns[BULLET]A large-print sudoku and crossword book[BULLET]Handwritten card with your message",
  "2": "This box features products selected to encourage organization, peacefulness, and relaxation during recovery.[BULLET]Travel Pouch: Choose one of three fun patterns[BULLET]Journal: Choose a cover pattern[BULLET]JOY Candle: Infused, homemade soy candle[BULLET]Flower Food: 2 packets to keep flowers happy[BULLET]Sleep mask[BULLET]Handwritten card with your message",
  "3": "This box is perfect for anyone who will be on bed rest or needs help combatting boredom during quarantine."
}
var buttonTextMap = {
  "1": "Shop Distraction Box",
  "2": "Shop Comfort Box",
  "3": "Shop Stuck At Home Box"
}
var productTitleMap = {
  "1":"Distraction Box",
  "2":"Comfort Box",
  "3":"Stuck At Home Box"
}

var productPriceMap = {
  "1":"$55",
  "2":"$65",
  "3":"$70"
}
var productLinkMap = {
  "1":"distraction-box",
  "2":"comfort-box",
  "3":"stuck-at-home-box"
}

var productImage={
  "1": distractionBox,
  "2": comfortBox,
  "3": stuckAtHomeBox
}

const products = [
  {productImage: productImage["1"], descriptionText: descriptionTextMap["1"], buttonText: buttonTextMap["1"], productTitle: productTitleMap["1"], productPrice: productPriceMap["1"], productLink: productLinkMap["1"], otherProducts: [
    {productImage: productImage["2"], descriptionText: descriptionTextMap["2"], buttonText: buttonTextMap["2"], productTitle: productTitleMap["2"], productPrice: productPriceMap["2"], productLink: productLinkMap["2"]},
    {productImage: productImage["3"], descriptionText: descriptionTextMap["3"], buttonText: buttonTextMap["3"], productTitle: productTitleMap["3"], productPrice: productPriceMap["3"], productLink: productLinkMap["3"]}]
  },
  {productImage: productImage["2"], descriptionText: descriptionTextMap["2"], buttonText: buttonTextMap["2"], productTitle: productTitleMap["2"], productPrice: productPriceMap["2"], productLink: productLinkMap["2"], otherProducts: [
    {productImage: productImage["1"], descriptionText: descriptionTextMap["1"], buttonText: buttonTextMap["1"], productTitle: productTitleMap["1"], productPrice: productPriceMap["1"], productLink: productLinkMap["1"]},
    {productImage: productImage["3"], descriptionText: descriptionTextMap["3"], buttonText: buttonTextMap["3"], productTitle: productTitleMap["3"], productPrice: productPriceMap["3"], productLink: productLinkMap["3"]}]
  },
  {productImage: productImage["3"], descriptionText: descriptionTextMap["3"], buttonText: buttonTextMap["3"], productTitle: productTitleMap["3"], productPrice: productPriceMap["3"], productLink: productLinkMap["3"], otherProducts: [
    {productImage: productImage["1"], descriptionText: descriptionTextMap["1"], buttonText: buttonTextMap["1"], productTitle: productTitleMap["1"], productPrice: productPriceMap["1"], productLink: productLinkMap["1"]},
    {productImage: productImage["2"], descriptionText: descriptionTextMap["2"], buttonText: buttonTextMap["2"], productTitle: productTitleMap["2"], productPrice: productPriceMap["2"], productLink: productLinkMap["2"]}]
  }
]
export default products

import React from 'react';
import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './Boxone.css';
import '@fortawesome/fontawesome-free/js/all.js';
import athomebox1 from './images/at-home-box.png';
import athomebox2 from './images/at-home-box.png';
import athomebox3 from './images/at-home-box.png';
import distraction1 from './images/distraction-box.png'
import distraction2 from './images/distraction-box-2.png'
import distraction3 from './images/distraction-box-3.png'
import comfort1 from './images/comfort-box.png'
import comfort2 from './images/comfort-box-2.png'
import comfort3 from './images/comfort-box-3.png'

class Boxone extends React.Component{
  constructor(props){
    super(props)
    this.state = {
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if (this.props.productName === nextProps.productName){
      return false
    }
    else if (this.props.productName !== nextProps.productName){
      return true
    }
  }

  render(){
    var productImageMapper = {
      "Distraction Box": [distraction1, distraction2, distraction3],
      "Comfort Box": [comfort1, comfort2, comfort3],
      "Stuck At Home Box": [athomebox1, athomebox2, athomebox3]
    }

    return(
      <div>
        <Carousel plugins={['infinite', 'clickToChange', {
          resolve: arrowsPlugin,
          options: {
            arrowLeft: <button id="left-arrow" className="fas fa-arrow-circle-left" />,
            arrowLeftDisabled:<button></button>,
            arrowRight: <button id="right-arrow" className="fas fa-arrow-circle-right" />,
            arrowRightDisabled: <button></button>,
            addArrowClickHandler: true
          }
        }]}
          slides={
            [
              (<img alt="distraction-box" src={productImageMapper[this.props.productName][0]} id="1" className="product-image-slider"/>),
              (<img alt="comfort-box" src={productImageMapper[this.props.productName][1]} id="2" className="product-image-slider"/>),
              (<img alt="all-box" src={productImageMapper[this.props.productName][2]} id="3" className="product-image-slider"/>)
            ]
          }
          draggable={false}
        />
      </div>
    )
  }
}

export default Boxone;

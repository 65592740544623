import React from 'react';
import './Productinfo.css'
import step3 from './images/step3.svg'
import step2 from './images/step2.svg'
import step1 from './images/step1.svg'

class Productinfo extends React.Component{
constructor(props){
  super(props)
  this.state = {
    default: null,
  }
}


render(){
  return(
    <section className="productInfo">
      <div className="productInfo-container">
        <div className="productInfo-container-h2">
          <h2>How It Works</h2>
        </div>
        <div className="productInfo-container-row">
          <div className="productInfo-container-row-box">
            <div className="productInfo-container-row-box-image">
              <img alt="step-1-box" src={step1} />
            </div>
            <div className="productInfo-container-row-box-header">
              <h4>Choose a Box</h4>
            </div>
            <div className="productInfo-container-row-box-header-text-container">
              <div className="productInfo-container-row-box-header-text">
                Select from our curated boxes, specifically designed to meet the needs of your loved one.
              </div>
            </div>
          </div>
          <div className="productInfo-container-row-box">
            <div className="productInfo-container-row-box-image">
              <img alt="step-2-box" src={step2} />
            </div>
            <div className="productInfo-container-row-box-header">
              <h4>Personalize</h4>
            </div>
            <div className="productInfo-container-row-box-header-text-container">
              <div className="productInfo-container-row-box-header-text">
                Customize your choice of gifts. Then, tell us what you want to say and we will hand write your note for you.
              </div>
            </div>
          </div>
          <div className="productInfo-container-row-box">
            <div className="productInfo-container-row-box-image">
              <img alt="step-3-box" src={step3} />
            </div>
            <div className="productInfo-container-row-box-header">
              <h4>Send To Their Door</h4>
            </div>
            <div className="productInfo-container-row-box-header-text-container">
              <div className="productInfo-container-row-box-header-text">
                Tell us where to send the care package and we’ll ship anywhere in the continental US.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
}

export default Productinfo;

import React from 'react';
import './Impact.css';
import impact from './images/ourmission.svg';

class Impact extends React.Component{
constructor(props){
  super(props)
  this.state = {
    default: null,
  }
}


render(){
  return(
    <section className="impact-container">
      <div className="impact-section">
        <div className="mission-left-box">
          <img alt="impact-box" src={impact} className="left-box-image" />
        </div>
        <div className="mission-right-box">
          <div className="right-box-h2-holder">
            <h2 className="right-box-h2">Our Mission</h2>
          </div>
          <div className="right-box-p-holder">
            <p>Nightingale was founded to guide you through one of life's most challenging moments - when someone you love is sick. We know what it's like, and know it's hard to know just how to help. We remove the guesswork and provide patients with what they really want and need. We make it easy to give the perfect gift when your loved one needs it most.</p>
          </div>
          <div className="right-box-a-holder">
            <a className="right-box-yellow-action-button" href="#">Learn More</a>
          </div>
        </div>
      </div>
    </section>
  )
}
}

export default Impact;

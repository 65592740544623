import React from "react";
import Navigation from "../components/Navigation/Navigation.js";
import Footer from "../components/Footer/Footer.js";
import Main from "../components/Main/Main.js";
import Impact from "../components/Impact/Impact.js";
import Productinfo from "../components/Productinfo/Productinfo.js";
import Boxinfo from "../components/Boxinfo/Boxinfo.js";
import Testimonials from "../components/Testimonials/Testimonials.js";
import Ecap from "../components/eCap/eCap.js";
import "./background.css";
import SEO from '@americanexpress/react-seo';

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      default: null
    };
  }

  render() {
    return (
      <div>
        <SEO
          title="Nightingale Guide Care Boxes"
          description="Nightingale helps support sick friends and loved ones with customized care packages and gifts that provide much-needed emotional support."
          keywords={["cancer care package", "emotional care box", "long term care box", "covid gifts", "cancer gifts", "hospital care package"]}
          openGraph={{
            title: "Nightingale Guide Care Boxes",
            description: "Nightingale helps support sick friends and loved ones with customized care packages and gifts that provide much-needed emotional support."
          }}
          twitterCard={{
            title: "Nightingale Guide Care Boxes",
            description: "Nightingale helps support sick friends and loved ones with customized care packages and gifts that provide much-needed emotional support."
          }}
        />
        <Navigation />
        <div className="background-holder">
          <Main />
        </div>
        <Productinfo />
        <Boxinfo />
        <Testimonials />
        <Impact />
        <Ecap />
        <Footer />
      </div>
    );
  }
}

export default Homepage;

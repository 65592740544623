import React from 'react';
import Navigation from '../components/Navigation/Navigation.js';
import Footer from '../components/Footer/Footer.js';
import Ecap from '../components/eCap/eCap.js';
import Boxslider from '../components/BoxSlider/Boxslider.js';
import DesktopShop from '../components/DesktopShop/DesktopShop.js';
import './shop.css';
import SEO from '@americanexpress/react-seo';

class Shop extends React.Component{
constructor(props){
  super(props)
  this.state = {
    default: null,
    device: "desktop"
  }
}

componentDidMount(){
  this.handleWindowResize()

  window.addEventListener("resize", this.handleWindowResize.bind(this));
}

handleWindowResize = () => {
  var windowSize = window.innerWidth
  const isMobile = windowSize >= 320 && windowSize <= 767;
  const isTablet = windowSize >= 768 && windowSize <= 1024;
  if (isMobile){
    this.setState({
      device: "mobile"
    })
  }
  else {
    this.setState({
      device: "desktop"
    })
  }
}


  render(){
    return(
      <div className="shopbg">
        <SEO
          title="Nightingale Guide Box Shop"
          description="Nightingale gift boxes provide emotional support for anyone going through recovery who need something to brighten their days. Our three boxes relax, distract, and entertain anyone who needs help getting through an injury or illness."
          keywords={["distraction box", "comfort box", "stuck at home box", "quarantine box", "relaxation box", "nightingale guide gift basket"]}
          openGraph={{
            title: "Nightingale Guide Box Shop",
            description: "Nightingale gift boxes provide emotional support for anyone going through recovery who need something to brighten their days. Our three boxes relax, distract, and entertain anyone who needs help getting through an injury or illness."
          }}
          twitterCard={{
            title: "Nightingale Guide Box Shop",
            description: "Nightingale gift boxes provide emotional support for anyone going through recovery who need something to brighten their days. Our three boxes relax, distract, and entertain anyone who needs help getting through an injury or illness."
          }}
        />
        <Navigation />
        <div className="shopholder">
          <h1 className="shop-h1">Gift a Unique Box</h1>
          <div className="desktop-products">
          </div>
          {this.state.device === "mobile" ?
            <Boxslider />
          :
            <DesktopShop />
          }
        </div>
        <Ecap />
        <Footer />
      </div>
    )
  }
}

export default Shop;

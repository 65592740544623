import React from 'react';
import Navigation from '../components/Navigation/Navigation.js';
import Footer from '../components/Footer/Footer.js';
import Ecap from '../components/eCap/eCap.js';
import Boxone from './Boxone.js';
import './product.css';
import {
  Link
} from "react-router-dom";
import products from '../components/Globals/productData.js';
import none from './images/box1.png'
import SEO from '@americanexpress/react-seo';


class Product extends React.Component{
constructor(props){
  super(props)
  this.state = {
    included: "minimized",
    customize: "minimized",
    includedProducts: [],
    customizedProducts: [],
    customMessage: "",
    loadingCart: false,
    floatingBuy: "1"
  }
}

componentDidMount(){
  window.scrollTo(0, 0)

  if (typeof window !== "undefined") {
    window.onscroll = () => {
      let currentScrollPos = window.pageYOffset;
      let maxScroll = document.body.scrollHeight - window.innerHeight;
      let percentageScroll = (currentScrollPos / maxScroll)
      if (percentageScroll < .21) {
        this.setState({ floatingBuy: "1" })
      } else {
        this.setState({ floatingBuy: "0" })
      }
    }
  }
  // WE USE PATHNAME HERE INSTEAD OF LOCATION.HREF IN ORDER TO ACCOMODATE UTM PARAMETER USAGE
  var currentProduct = window.location.pathname.split("/shop/")[1]

  var urlMatrix = {
    "distraction-box":"1",
    "comfort-box": "2",
    "stuck-at-home-box": "3"
  }
  // THIS FETCH HANDLES PULLING IN INCLUDED PRODUCTS FOR THE BOX, AND USES A PLACEHOLDER IMAGE IF NO IMAGE HAS BEEN DEFINED IN SHOPIFY
  fetch("https://nightingaleguide-backend.herokuapp.com/includedproducts/" + urlMatrix[currentProduct])
  .then(res => res.json())
  .then(res => {
    var cleanedRes = res.map(product => {
      var productToAdd = {}
      // THESE TERNARIES TAKE CARE OF ANY PRODUCTS THAT WE MIGHT FORGET TO GIVE REQUIRED ATTRIBUTES
      product.productName === undefined ? (productToAdd["productName"] = "None") : (productToAdd["productName"] = product.productName)
      product.productImage === undefined ? (productToAdd["productImage"] = none) : (productToAdd["productImage"] = product.productImage.src)
      product.productCustomizable === undefined ? (productToAdd["productCustomizable"]) = false : (productToAdd["productCustomizable"] = product.productCustomizable)
      return productToAdd
    })
    this.setState({
      includedProducts: cleanedRes
    }, () => {
        // THIS FETCH HANDLES PULLING IN PRODUCTS THAT NEED TO BE CUSTOMIZED BY THE USER
        // COMES IMMEDIATELY AFTER INITIAL FETCH IS FINISHED SO THAT PRODUCTS WITH CUSTOMIZABLE TAGS
        // ARE FETCHED
        var customizedProductArray = this.state.includedProducts.filter(product => product.productCustomizable === true).map(product => product.productName)
        var customizedString = ""
        // THIS LINE OF CODE SETS THE DIVISOR FOR EACH PRODUCT AS @@ - WE THEN SPLIT
        // THIS VALUE SERVER SIDE TO TELL WHAT PRODUCT CHOICES WE NEED TO PROVIDE FOR
        // EACH ITEM LISTED
        customizedProductArray.forEach(productName => customizedString += productName + "@@")
        fetch(`https://nightingaleguide-backend.herokuapp.com/customizableproducts/${customizedString}`)
        .then(res => res.json())
        .then(res => {
          // THIS LINE OF CODE HANDLES WHAT CUSTOM PRODUCTS ARE SELECTED UPON INITIAL RENDER.
          // THEY ARE CHANGED WITH THE SELECTPRODUCT FUNCTION
          res.forEach(obj => obj.forEach((product, index) => index === 0 ? (product["selected"] = true) : (product["selected"] = false)))
          // res.map((obj, index) => obj.map((product)

          this.setState({
            customizedProducts: res,
            includedProducts: [...this.state.includedProducts.filter(product => product.productCustomizable === false)]
          })
        })
    })
    }
  )
}

shouldComponentUpdate(nextProps, nextState){
  // fixes expand bug
  if ((this.state.customizedProducts !== nextState.customizedProducts) || (this.state.included !== nextState.included) || (this.state.customize !== nextState.customize)){
    return true
  }
  // FIXES MENU BUG
  if (nextProps.location.state !== undefined){
    return true
  }
  else if (nextProps.location.state === undefined){
    nextProps.location.state = this.props.location.state
    return false
  }

}

componentDidUpdate(prevProps) {
  if (this.props.location !== prevProps.location) {
    window.scrollTo(0, 0)
  }

  if (this.props.location.state !== prevProps.location.state){
    this.setState({
      included: "minimized",
      customize: "minimized",
    })

    var currentProduct = window.location.pathname.split("/shop/")[1]

    var urlMatrix = {
      "distraction-box":"1",
      "comfort-box": "2",
      "stuck-at-home-box": "3"
    }
    // THIS FETCH HANDLES PULLING IN INCLUDED PRODUCTS FOR THE BOX, AND USES A PLACEHOLDER IMAGE IF NO IMAGE HAS BEEN DEFINED IN SHOPIFY
    fetch("https://nightingaleguide-backend.herokuapp.com/includedproducts/" + urlMatrix[currentProduct])
    .then(res => res.json())
    .then(res => {
      var cleanedRes = res.map(product => {
        var productToAdd = {}
        // THESE TERNARIES TAKE CARE OF ANY PRODUCTS THAT WE MIGHT FORGET TO GIVE REQUIRED ATTRIBUTES
        product.productName === undefined ? (productToAdd["productName"] = "None") : (productToAdd["productName"] = product.productName)
        product.productImage === undefined ? (productToAdd["productImage"] = none) : (productToAdd["productImage"] = product.productImage.src)
        product.productCustomizable === undefined ? (productToAdd["productCustomizable"]) = false : (productToAdd["productCustomizable"] = product.productCustomizable)
        return productToAdd
      })

      this.setState({
        includedProducts: cleanedRes
      }, () => {
          // THIS FETCH HANDLES PULLING IN PRODUCTS THAT NEED TO BE CUSTOMIZED BY THE USER
          // COMES IMMEDIATELY AFTER INITIAL FETCH IS FINISHED SO THAT PRODUCTS WITH CUSTOMIZABLE TAGS
          // ARE FETCHED
          var customizedProductArray = this.state.includedProducts.filter(product => product.productCustomizable === true).map(product => product.productName)
          var customizedString = ""

          // THIS LINE OF CODE SETS THE DIVISOR FOR EACH PRODUCT AS @@ - WE THEN SPLIT
          // THIS VALUE SERVER SIDE TO TELL WHAT PRODUCT CHOICES WE NEED TO PROVIDE FOR
          // EACH ITEM LISTED
          customizedProductArray.forEach(productName => customizedString += productName + "@@")

          fetch(`https://nightingaleguide-backend.herokuapp.com/customizableproducts/${customizedString}`)
          .then(res => res.json())
          .then(res => {
            // THIS LINE OF CODE HANDLES WHAT CUSTOM PRODUCTS ARE SELECTED UPON INITIAL RENDER.
            // THEY ARE CHANGED WITH THE SELECTPRODUCT FUNCTION
            res.forEach(obj => obj.forEach((product, index) => index === 0 ? (product["selected"] = true) : (product["selected"] = false)))
            // res.map((obj, index) => obj.map((product)

            this.setState({
              customizedProducts: res,
              includedProducts: [...this.state.includedProducts.filter(product => product.productCustomizable === false)]
            })
          })
      })
      }
    )
  }
}

buyProduct = (target) => {

  var buttonText = target.target.id
  var webUrl = null

  this.setState({
    loadingCart: true
  })

  var boxMatrix = {
    "Shop Distraction Box":"1",
    "Shop Comfort Box": "2",
    "Shop Stuck At Home Box": "3"
  }
  // THESE ARE THE IDS OF STATIC AND SELECTED CUSTOMIZABLE PRODUCTS THAT ARE PASSED INTO THE QUERY TO LET
  // THE BACKEND KNOW WHICH PRODUCTS NEED TO BE ADDED - MAY WANT TO REFACTOR TO IDS
  var staticProducts = this.state.includedProducts.filter(product => product.productCustomizable === false).map(product => product.productName)
  var dynamicProductsSelected = this.state.customizedProducts.flat().filter(product => product.selected === true).map(product=>product.title)

  // THE CODE BELOW CREATES AN ARRAY OF ALL PRODUCT NAMES TO ADD TO QUERY
  var productIdsToAdd = [...staticProducts, ...dynamicProductsSelected]

  // THIS FOREACH FUNCTION ADDS EVERYTHING INTO A STRING TO BE PASSED WITH THE GET FETCH
  var productQueryString = ""
  productIdsToAdd.forEach(productName => productQueryString += productName + "@@")

  //THIS ADDS A CUSTOM MESSAGE TO THE GET FETCH
  var customMessage = "NONE"

  if (this.state.customMessage !== ""){
    customMessage = this.state.customMessage
  }

  fetch("https://nightingaleguide-backend.herokuapp.com/shopify/" + boxMatrix[buttonText] + "?products=" + productQueryString + "&message=" + customMessage)
  .then(res => res.json())
  .then(res => {
    webUrl = res
    window.location.assign(webUrl)
    }
  )

}

expandChanger = (target) => {
  if (target.target.id.includes("included-min")){
    if (this.state.included === "minimized"){
      this.setState({
        included: "expanded"
      })
    }
  }

  if (target.target.id.includes("customized-min")){
    if (this.state.customize === "minimized"){
      this.setState({
        customize: "expanded"
      })
    }
  }

  if (target.target.id.includes("included-open")){
    if (this.state.included === "expanded"){
      this.setState({
        included: "minimized"
      })
    }
  }

  if (target.target.id.includes("customized-open")){
    if (this.state.customize === "expanded"){
      this.setState({
        customize: "minimized"
      })
    }
  }
}

selectProduct = (target) => {
  // THESE GET THE NAME AND TYPE OF PRODUCTS SELECTED FROM THE DOM
  var productType = target.target.parentNode.getAttribute("type")
  var productTitle = target.target.parentNode.getAttribute("name")
  // THIS MAKES A COPY OF STATE FOR US TO THEN INJECT BACK IN ONCE EDITED
  var customizedProducts = [...this.state.customizedProducts]
  // THIS PULLS OUT ONLY THE RELEVANT ITEMS
  var productTypeToChange = customizedProducts.flat().filter(product => product.productType === productType)
  // THIS CHANGES SELECTED STATE ON EACH RELEVANT ITEM
  productTypeToChange.forEach(object => {object.title === productTitle ? (object.selected = true) : (object.selected = false)})
  // THIS NESTED LOOP IDENTIFIES WHICH ARRAY IS OUT OF DATE AND REPLACES IT WITH THE UPDATED ONE
  for (var i=0; i<customizedProducts.length; i++){
    for (var j=0; j<customizedProducts[i].length; j++){
      if (customizedProducts[i][j].productType === productTypeToChange[0].productType){
        customizedProducts[i] = productTypeToChange
        break
      }
    }
  }
  this.setState({
    customizedProducts: customizedProducts
  })
}

noteHandler = (target) => {

  this.setState({
    customMessage: target.target.value
  })
}

  render(){
    // productSelector ensures that page always renders regardless of pathway
    var productSelector = products.filter(productName => productName.productLink === window.location.pathname.split("/shop/")[1])[0]
    var descriptionTextFormatted = productSelector.descriptionText.split("[BULLET]")
    var descriptionTextStarter = descriptionTextFormatted.shift()
    return(
    <div>
      <SEO
        title={productSelector.productTitle + " | Nightingale Guide Gift Box"}
        description={descriptionTextStarter.toString()}
        keywords={["distraction box", "comfort box", "stuck at home box", "quarantine box", "relaxation box", "nightingale guide gift basket"]}
        openGraph={{
          title: productSelector.productTitle + " | Nightingale Guide Gift Box",
          description: descriptionTextStarter.toString()
        }}
        twitterCard={{
          title: productSelector.productTitle + " | Nightingale Guide Gift Box",
          description: descriptionTextStarter.toString()
        }}
      />
      <div className="productbg">
        <Navigation />
        <div className="productholder">
          <div className="h1-product-holder">
            <h1 className="product-h1">{productSelector.productTitle}</h1>
          </div>
          <Boxone key={productSelector.productTitle} productName={productSelector.productTitle}/>
          <div className="product-info-holder">
            <div className="product-info-price-holder">{productSelector.productPrice}</div>
            <div className="box-info-description-holder">
              <div className="description-text">{descriptionTextStarter}</div>
              <div className="included-product-info-holder">
                <h4 className="included-h4">Included in Every Box:</h4>
              </div>
                <div className="included-product-info-description-holder">
                  {this.state.includedProducts.map((product, index) =>
                    <React.Fragment key={"related-product-" + Math.random() + index}>
                      <div className="included-product-column">
                        <div>
                          <img alt={"included" + product.productImage} className="included-images" src={product.productImage} />
                        </div>
                        <div className="included-product-name">
                          {product.productName}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              <div className="product-info-holder">
                <h4 className="customize-h4">Customizable in Every Box:</h4>
              </div>
                {this.state.customizedProducts.map((obj, index) => {
                    return (
                      <div className="customize-product-row-holder">
                      <div className="customize-header-holder">
                        <h5 className="customize-h5">{obj[0].productType}</h5>
                        <h6 className="customize-h6">(Select one of the following)</h6>
                      </div>
                      <div className="customize-product-holder">
                        {obj.map((product) => {
                          return (
                            <div className={product.selected === true ? "customize-product-column-selected" : "customize-product-column"} type={product.productType} name={product.title} onClick={this.selectProduct}>
                              <div type={product.productType} name={product.title} className="customize-image-holder">
                                {product.images[0] !== undefined ?
                                  <img alt="included-images" className="included-images" src={product.images[0].src} />
                                :
                                  <img alt="included-images" className="included-images" src={none} />
                                }
                              </div>
                              <div>
                                {product.title}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      </div>
                    )
                })}
            </div>

            <div onClick={this.expandChanger}>
              {this.state.customize === "minimized" ?
                <div id={productSelector.productLink + "div-customized-min"} className="h4-holder">
                  <h4 id={productSelector.productLink + "h4-customized-min"}>Add a Custom Message +</h4>
                </div>
              :
                <div id={productSelector.productLink + "div-customized-open"} className="h4-holder">
                  <h4 id={productSelector.productLink + "h4-customized-open"}>Add a Custom Message -</h4>
                  <div className="product-info-customize-holder">
                    <div className="note-section">
                      <div className="customize-header-holder">
                        <h5 className="customize-h5">Custom Handwritten Message</h5>
                        <div className="customize-header-holder-h6">
                          <h6 className="customize-h6">A member of our team will handwrite your message on a notecard. Please make sure to write your note as you would like it to appear.</h6>
                        </div>
                      </div>
                      <div className="note-box-holder">
                        <textarea className="note-box" onChange={this.noteHandler}></textarea>
                      </div>
                    </div>

                  </div>
                </div>
              }
            </div>
            {productSelector.productTitle !== "You Deserve It All Box" ?
              <div className="product-button-holder-product" onClick={this.buyProduct}>
                {this.state.loadingCart === false ?
                  <div className="product-botton-add-to-cart" id={productSelector.buttonText}>
                    Buy Now
                  </div>
                :
                  <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                }
              </div>
            :
              <div className="product-button-holder-product">
                <div className="product-botton-add-to-cart" id={productSelector.buttonText}>
                  Coming Soon!
                </div>
              </div>
            }
            <h5 className="product-info-view-boxes-header">View Other Boxes</h5>
            <div className="product-other-boxes">
              {productSelector.otherProducts.map((product, index) =>
                <React.Fragment key={"topproductholder" + index}>
                  <div>
                    <Link to={{pathname: `/shop/${product.productLink}`, state: {data: products.filter(productsInList => productsInList.productTitle === product.productTitle)[0]}}}>
                      <img alt={"product-box-image" + product.productImage} className="product-other-boxes-image" src={product.productImage} />
                    </Link>
                    <div className="product-other-boxes-holder">
                      <Link to={{pathname: `/shop/${product.productLink}`, state: {data: products.filter(productsInList => productsInList.productTitle === product.productTitle)[0]}}} className="product-other-boxes-text">
                        {product.buttonText}
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
            )}
            </div>
          </div>
          </div>
        <Ecap />
        <Footer />
      </div>
      {/*
        <div className="floating-buy-button" style={{opacity: this.state.floatingBuy}}>
          <div className="floating-product-button-holder-product" onClick={this.buyProduct}>
            {this.state.loadingCart === false ?
              <div className="floating-product-botton-add-to-cart" id={productSelector.buttonText}>
                Buy Now
              </div>
            :
              <div className="floating-product-botton-add-to-cart" id={productSelector.buttonText}>
                <div className="floating-ring"><div></div><div></div><div></div><div></div></div>
              </div>
            }
          </div>
        </div>
      */}
      </div>
    )
  }
}

export default Product;

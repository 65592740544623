import React, { useState, useRef, useEffect } from "react";
import logo from "./images/logo.png";
import "./Navigation.css";
import { ReactComponent as Menu } from "./images/hamburger-menu.svg";
import fallbackMenu from "./images/hamburger-menu.svg";
import { ReactComponent as Cart } from "./images/shopping-cart.svg";
import { ReactComponent as ArrowIcon } from "./images/left-arrow.svg";
import { ReactComponent as Blog } from "./images/blog.svg";
// import { ReactComponent as Registry } from "./images/cloud.svg";
// import { ReactComponent as Resources } from "./images/resources.svg";
// import { ReactComponent as Login } from "./images/login.svg";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

function Navigation(props) {
  return (
    <Navbar>
      <Link to={{ pathname: `/` }}>
        <img src={logo} alt="nightingale-logo" className="logoImage" />
      </Link>
      <NavItem icon={<Menu />}>
        <DropdownMenu />
      </NavItem>
    </Navbar>
  );
}

function Navbar(props) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
}

function NavItem(props) {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <li className="nav-item" ref={ref}>
      <a className="icon-button" onClick={() => setOpen(!open)}>
        <img className="hamburger-menu" src={fallbackMenu} />
      </a>
      {open && props.children}
    </li>
  );
}

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-button">{props.rightIcon}</span>
      </a>
    );
  }
  return (
    <div className="dropdown" style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to={{ pathname: `/shop/` }}>
            <DropdownItem leftIcon={<Cart />}>Shop</DropdownItem>
          </Link>
          {/*<DropdownItem leftIcon={<Registry />}>Registry</DropdownItem>*/}
          <Link to={{pathname: `/blog/`}}>
            <DropdownItem leftIcon={<Blog />}>Blog</DropdownItem>
          </Link>
          {/*<DropdownItem leftIcon={<Resources />} goToMenu="resources">
            Resources
          </DropdownItem>*/}
          {/*<DropdownItem leftIcon={<Login />}>Login/Signup</DropdownItem>*/}
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === "resources"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
      >
        <div className="menu">
          <DropdownItem leftIcon={<ArrowIcon />} goToMenu="main"></DropdownItem>
          <DropdownItem>Resource 1</DropdownItem>
          <DropdownItem>Resource 2</DropdownItem>
          <DropdownItem>Resource 3</DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Navigation;

// class Navigation extends React.Component{
// constructor(props){
//   super(props)
//   this.state = {
//     default: null,
//   }
// }

// render(){
//   return(
//     <header>
//       <nav className="siteHeaderContainer">
//         <div className="logoImage">
//           <a href="/">
//             <img src={logo} />
//           </a>
//         </div>
//         <div className="navMenus">
//           <a href="/products" className="leftMenuButtonText">
//             Shop
//           </a>
//           <a href="/registry" className="leftMenuButtonText">
//             Registry
//           </a>
//           <a href="/blog" className="leftMenuButtonText">
//             Blog
//           </a>
//           <a href="/blog" className="leftMenuButtonText">
//             Resources
//           </a>
//         </div>
//         <div className="rightMenu">
//           <a href="/login" className="loginText">
//             Log In
//           </a>
//           <a href="/signup" className="signupMenuButton">
//             Sign Up
//           </a>
//         </div>
//       </nav>
//     </header>
//   )
// }
// }
